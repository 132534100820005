import * as React from 'react';
import { PageProps } from 'gatsby';
import qs from 'query-string';
import { gql } from '@urql/core';
import { captureException } from 'utils';
import { CatalogLayout } from 'components/CatalogLayout';
import { PageError } from 'components/PageError';
import { BackButton } from 'components/BackButton';
import { Loader } from 'components/Loader';
import { AuthRequired } from 'components/AuthRequired';
import { Price } from 'components/Price';
import { MyOrderDetailsPageQuery, useMyOrderDetailsPageQuery } from 'generated/graphql';

type WrapperProps = {
  readonly id: number;
};

type TableBodyProps = {
  readonly item: NonNullable<NonNullable<MyOrderDetailsPageQuery['viewer']['user']>['order']>['items'][0];
};

const Wrapper: React.FC<WrapperProps> = ({ id, children }) => (
  <CatalogLayout>
    <BackButton className="text-2xl font-bold mt-7 mb-11 lg:text-xl lg:mt-3 lg:mb-6">Чек заказа № {id}</BackButton>
    {children}
  </CatalogLayout>
);

const removeQuantityFromName = (s: string) => s.replace(/~.*?$/g, '');

const units: { [key in 'KG' | 'PCS' | 'PACK']: string } = {
  KG: 'кг',
  PCS: 'шт',
  PACK: 'уп',
};

const tableHeader = 'text-left text-black text-xl font-normal h-[50px]';
const tableColHeader = 'text-[#7F7F7F] text-sm h-[22px] font-normal text-left w-[100px]';
const tableColText = 'text-sm h-[22px]';
const tableBorder = 'border-b border-b-[#DFE2E9] py-2';
const colSpan = 5;

const TableBody = ({ item }: TableBodyProps) => {
  if (!item.extended) {
    return null;
  }

  if (!item.extended.quantity) {
    return (
      <>
        <tr>
          <th className={tableHeader} colSpan={colSpan}>
            {removeQuantityFromName(item.product.name)}
          </th>
        </tr>
        <tr>
          <td>Вычеркнуто</td>
        </tr>
        <tr>
          <th colSpan={colSpan} className={tableBorder}>
            {' '}
          </th>
        </tr>
      </>
    );
  }

  const totalSum = item.extended.totalSum || 0;
  const { orderedTotalSum } = item.extended;

  return (
    <>
      <tr>
        <th className={tableHeader} colSpan={colSpan}>
          {removeQuantityFromName(item.product.name)}
        </th>
      </tr>
      <tr>
        <th className={tableColHeader}> </th>
        <th className={tableColHeader}>Кол</th>
        <th className={tableColHeader}>Цена</th>
        <th className={tableColHeader}>Сумма</th>
        <th className={tableColHeader}>Разница</th>
      </tr>
      <tr>
        <th className={tableColHeader}>Заказ</th>
        <td className={tableColText}>
          {item.extended.orderedQuantity} {units[item.extended.unit]}
        </td>
        <td className={tableColText} rowSpan={2}>
          <Price value={item.price} />
        </td>
        <td className={tableColText}>
          <Price value={orderedTotalSum} />
        </td>
        <td className={tableColText} rowSpan={2}>
          <Price value={Math.abs(totalSum - orderedTotalSum)} />
        </td>
      </tr>
      <tr>
        <th className={tableColHeader}>По факту</th>
        <td className={tableColText}>{`${item.extended.quantity} ${units[item.extended.unit]}`}</td>
        <td className={tableColText}>
          <Price value={totalSum} />
        </td>
      </tr>
      <tr>
        <th colSpan={colSpan} className={tableBorder}>
          {' '}
        </th>
      </tr>
    </>
  );
};

const MyOrderDetailsPage = ({ location }: PageProps) => {
  const qp = qs.parse(location.search);
  const id = qp.id && typeof qp.id === 'string' ? parseInt(qp.id, 10) : 0;

  const [queryRes, reexecuteQuery] = useMyOrderDetailsPageQuery({
    variables: { id },
    requestPolicy: 'network-only',
    pause: id === 0,
  });

  if (id === 0) {
    return null;
  }

  if (queryRes.error) {
    const retry = () => reexecuteQuery({ requestPolicy: 'network-only' });
    return (
      <Wrapper id={id}>
        <PageError error={queryRes.error} retry={retry} className="justify-center" />
      </Wrapper>
    );
  }

  if (queryRes.fetching && !queryRes.data) {
    return (
      <Wrapper id={id}>
        <Loader className="flex justify-center" />
      </Wrapper>
    );
  }

  if (!queryRes.data) {
    captureException(new Error('order details data is null'));
    return null;
  }

  if (!queryRes.data.viewer.user) {
    return (
      <Wrapper id={id}>
        <AuthRequired />
      </Wrapper>
    );
  }

  const items = queryRes.data.viewer.user.order?.items;

  if (!items || items.length === 0) {
    return (
      <Wrapper id={id}>
        <div>Заказ не найден</div>
      </Wrapper>
    );
  }

  return (
    <Wrapper id={id}>
      <div className="pb-7">
        <table>
          {items.map((item) => (
            <TableBody key={item.id} item={item} />
          ))}
        </table>
      </div>
    </Wrapper>
  );
};

gql`
  query MyOrderDetailsPageQuery($id: Int!) {
    viewer {
      id
      user {
        id
        order(id: $id) {
          id
          items {
            id
            price
            product {
              name
            }
            extended {
              quantity
              orderedQuantity
              unit
              totalSum
              orderedTotalSum
            }
          }
        }
      }
    }
  }
`;

export default MyOrderDetailsPage;
